exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prismic-chef-url-js": () => import("./../../../src/pages/{PrismicChef.url}.js" /* webpackChunkName: "component---src-pages-prismic-chef-url-js" */),
  "component---src-pages-prismic-page-url-js": () => import("./../../../src/pages/{PrismicPage.url}.js" /* webpackChunkName: "component---src-pages-prismic-page-url-js" */),
  "component---src-pages-prismic-product-url-js": () => import("./../../../src/pages/{PrismicProduct.url}.js" /* webpackChunkName: "component---src-pages-prismic-product-url-js" */),
  "component---src-pages-prismic-sign-up-url-js": () => import("./../../../src/pages/{PrismicSignUp.url}.js" /* webpackChunkName: "component---src-pages-prismic-sign-up-url-js" */),
  "component---src-pages-prismic-suppliers-url-js": () => import("./../../../src/pages/{PrismicSuppliers.url}.js" /* webpackChunkName: "component---src-pages-prismic-suppliers-url-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-templates-all-chefs-js": () => import("./../../../src/templates/AllChefs.js" /* webpackChunkName: "component---src-templates-all-chefs-js" */),
  "component---src-templates-all-products-js": () => import("./../../../src/templates/AllProducts.js" /* webpackChunkName: "component---src-templates-all-products-js" */)
}

