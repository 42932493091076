/* Gatsby Browser API
 * -------------------
 * The file gatsby-browser.js lets you respond to Gatsby-specific events within
 * the browser, and wrap your page components in additional global components.
 * The Gatsby Browser API gives you many options for interacting with the
 * client-side of Gatsby.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

const React = require("react");
const { CartProvider } = require("react-use-cart");
const { ShopProvider } = require("./src/context/ShopContext");
const { CustomerProvider } = require("./src/context/CustomerContext");

exports.wrapPageElement = ({ element }) => (
  <ShopProvider>
    <CustomerProvider>
      <CartProvider>{element}</CartProvider>
    </CustomerProvider>
  </ShopProvider>
);
