import React, { createContext, useContext, useState, useMemo } from "react";
import { navigate } from "gatsby";
import createPersistedState from "use-persisted-state";

import { functionsBase } from "../../config/site-config";

const useCustomerState = createPersistedState("customer");
const CustomerContext = createContext({});

const CustomerProvider = ({ children }) => {
  const [customer, setCustomer] = useCustomerState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const value = useMemo(() => {
    const handleRegistration = async ({ email, password }) => {
      if (!email)
        return {
          error: { message: "Εισάγετε το email σας." },
        };
      if (!password)
        return {
          error: { message: "Εισάγετε έναν κωδικό." },
        };

      const username = `${email.split("@")[0]}-${new Date().getTime()}`;

      const responce = await fetch(
        "https://hellochef.thinkplus.dev/api/auth/local/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, email, password }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.error) return res;

          setCustomer({
            ...customer,
            jwt: res.jwt,
          });

          return customer;
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
          return {
            error: { message: "Παρουσιάστηκε άγνωστο σφάλμα. Δοκιμάστε ξανά." },
          };
        });

      return responce;
    };

    const handleLogin = async ({ email, password }) => {
      const login = await fetch(
        "https://hellochef.thinkplus.dev/api/auth/local/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ identifier: email, password }),
        }
      )
        .then((res) => res.json())
        .then(async (auth) => {
          if (auth.error) return false;

          if (auth.jwt && auth.user?.email) {
            const stripeCustomer = await fetch(
              `${functionsBase}/retrieve-customer`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: auth.user.email }),
              }
            )
              .then((res) => res.json())
              .then((res) => {
                if (res.error) return false;

                setCustomer({
                  ...res.customer,
                  jwt: auth.jwt,
                });
                return true;
              });

            return stripeCustomer;
          }

          return null;
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
          return false;
        });

      return login;
    };

    const handleLogout = (callback) => {
      setCustomer({});
      setPaymentMethods([]);

      if (typeof callback === "function") callback();

      navigate("/");
    };

    const isLoggedIn = () => !!customer?.email;

    const customerDefaultPayment =
      customer?.invoice_settings?.default_payment_method ||
      customer?.default_source;

    return {
      customer,
      setCustomer,
      subscriptions,
      setSubscriptions,
      paymentMethods,
      setPaymentMethods,
      handleRegistration,
      handleLogin,
      handleLogout,
      isLoggedIn,
      customerDefaultPayment,
    };
  }, [customer, subscriptions, paymentMethods, setCustomer]);

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};

const useCustomer = () => {
  const context = useContext(CustomerContext);

  if (context === undefined) {
    throw new Error("useCustomer must be used within CustomerProvider");
  }

  return context;
};

export { CustomerContext, CustomerProvider, useCustomer };
