module.exports = {
  siteUrl: "https://hello-chef.gr/",
  siteLang: "el-GR",
  siteCurrency: "EUR",
  title: "Hello Chef Meal-kit",
  shortTitle: "Hello Chef",
  description: "Hello-Chef Μeal-Κit: fresh weekly meal-kits for home delivery",
  logoUrl: "https://hello-chef.gr/images/hello-chef-logo.png",

  // Values used in manifest
  siteBackgroundColor: "#e5e5e5",
  siteThemeColor: "#347e6e",
  siteIcon: require.resolve("../src/images/favicon.png"),

  // Image Details used as fallback at metadata
  defaultImage: "https://hello-chef.gr/images/hello-chef-logo.png",
  defaultImageWidth: "512",
  defaultImageHeight: "512",
  defaultImageAlt: "Hello Chef",

  // Public Urls
  functionsBase: "https://hello-chef.thinkplus.workers.dev",
};
