import React, { createContext, useContext, useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { loadStripe } from "@stripe/stripe-js";

const ShopContext = createContext({});

const ShopProvider = ({ children }) => {
  const query = useStaticQuery(graphql`
    query shopDataContext($logoWidth: Int = 260) {
      prismicShop {
        ...prismicShopFragment
      }
      prismicSignUp {
        ...prismicSignUpFragment
      }
      prismicAllProducts {
        url
      }
      allStripePrice(
        filter: {
          active: { eq: true }
          product: { name: { eq: "Subscription Box" } }
        }
        sort: { fields: unit_amount, order: ASC }
      ) {
        edges {
          node {
            active
            id
            unit_amount
            transform_quantity {
              divide_by
            }
            metadata {
              discount
            }
          }
        }
      }
    }
  `);

  // Create box objects from stripe content
  const boxes = query.allStripePrice.edges.map((item) => {
    const box = item?.node;
    const discount = Number(box.metadata.discount);

    return {
      id: box.id,
      productLimit: box.transform_quantity.divide_by,
      meals: box.transform_quantity.divide_by * 2,
      discount,
      _price: box.unit_amount,
      price: box.unit_amount / 100,
      compareAtPrice:
        discount < 100
          ? box.unit_amount / (100 - discount)
          : (box.unit_amount / 100) * 2,
      pricePerMeal:
        box.unit_amount / (box.transform_quantity.divide_by * 2) / 100,
    };
  });

  // Load stripe
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

  // Shop context value
  const value = useMemo(
    () => ({
      ...query.prismicShop.data,
      signUp: query.prismicSignUp.url,
      ...query.prismicSignUp.data,
      allProducts: query.prismicAllProducts.url,
      boxes,
      stripePromise,
    }),
    [
      boxes,
      query.prismicAllProducts.url,
      query.prismicShop.data,
      query.prismicSignUp.data,
      query.prismicSignUp.url,
      stripePromise,
    ]
  );

  return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>;
};

const useShop = () => {
  const context = useContext(ShopContext);

  if (context === undefined) {
    throw new Error("useShop must be used within ShopProvider");
  }

  return context;
};

export { ShopContext, ShopProvider, useShop };
